import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: import.meta.env.PUBLIC_SENTRY_DSN,
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: "v2024.12-1580414283",
  tracesSampleRate: 0,
  integrations: [new Sentry.Replay()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});